import Stripe from 'stripe';
import datasource from '../datalayer';
import orderStatus from 'constants/orderStatus';
import paymentStatus from 'constants/paymentStatus';
import paymentType from 'constants/paymentType';
import subscriptionStatus from 'constants/subscriptionStatus';
import { initiatePayment } from 'utils/gtag';
import ApiService from 'services/ApiService';

const getStripeInstance = (activeSite) => Stripe(activeSite.stripe_secret_key);

const getPriceForPeriod = (period = 'monthly', activeSite) => {
  if (period === 'monthly') return activeSite.stripe_monthly_price_id;
  if (period === 'yearly') return activeSite.stripe_yearly_price_id;
};

/**
 * @param {*} event - Triggered when a payment is successful
 * @returns
 */
export async function onPaymentSucceededWebhook(event) {
  const paymentIntent = event.data.object;
  const orders =
    await datasource.getOrdersForPaymentVerificationUsingPaymenetIntentId({
      paymentIntentId: paymentIntent.id,
      paymentType: paymentType.stripe,
    });

  if (orders.length === 0) {
    return {
      message: 'There is no order with the given payment intent id',
    };
  }
  const order = orders[0];
  const siteId = order.site_id.id;
  const activeSite = await datasource.getSiteStripeDetailsById(siteId);
  const hydratedEvent = await getEvent(event.id, activeSite);
  if (
    hydratedEvent.data.object.id !== paymentIntent.id &&
    hydratedEvent.data.object.status !== paymentIntent.status
  ) {
    return {
      message: 'The event is not valid',
    };
  }

  if (paymentIntent.status === paymentStatus.succeeded) {
    if (order.payment_status !== paymentStatus.succeeded) {
      const result = await datasource.updateOrder({
        id: order.id,
        data: {
          payment_status: paymentStatus.succeeded,
          status: orderStatus.active,
        },
      });
      if (result && result.id) {
        console.log('Order status updated by stripe webhook');
        return {
          message: 'Payment status updated successfully',
        };
      }
    } else {
      console.log('Order status is already updated');
    }
  }
}

export async function onSubscriptionUpdatedWebhook(rawEvent) {
  const hydratedUsers = await datasource.getUserByStripeId({
    variables: {
      stripe_id: rawEvent.data.object.customer,
    },
  });
  const hydratedUser = hydratedUsers.length === 0 ? null : hydratedUsers[0];

  const activeSite = await datasource.getSiteStripeDetailsById(
    hydratedUser.site_id.id,
  );
  const event = await getEvent(rawEvent.id, activeSite);

  if (event.data.object.customer !== rawEvent.data.object.customer) {
    return {
      message: 'The event is not valid',
    };
  }

  const subscription = event.data.object;
  const subscriptionEndDate = new Date(subscription.current_period_end * 1000);

  if (hydratedUser) {
    const status = subscription.status;
    if (status === subscriptionStatus.active) {
      if (subscription.cancel_at) {
        await datasource.updateUserDetails({
          data: {
            subscription_end_date: subscriptionEndDate,
            subscription_status: subscriptionStatus.canceled,
          },
          id: hydratedUser.id,
        });
        return {
          message: 'Subscription marked as canceled and date updated',
        };
      }
      await datasource.updateUserDetails({
        data: {
          subscription_end_date: subscriptionEndDate,
          subscription_status: subscriptionStatus.active,
        },
        id: hydratedUser.id,
      });
      return {
        message: 'Subscription marked as active and date updated',
      };
    }
    if (status === subscriptionStatus.past_due) {
      await datasource.updateUserDetails({
        data: {
          subscription_status: subscriptionStatus.past_due,
        },
        id: hydratedUser.id,
      });
      return {
        message: 'Subscription marked as past_due',
      };
    }
    if (
      [subscriptionStatus.canceled, subscriptionStatus.unpaid].includes(status)
    ) {
      await datasource.updateUserDetails({
        data: {
          subscription_end_date: subscriptionEndDate,
          subscription_status: subscriptionStatus.canceled,
        },
        id: hydratedUser.id,
      });
      return {
        message: 'Subscription marked as canceled and date updated',
      };
    }
  } else {
    return {
      message:
        'The subscription is not associated with any user in the database',
    };
  }
}

export async function getOrCreateCustomer(customer, activeSite) {
  const user = await datasource.getUserById({
    variables: {
      user_id: customer.id,
    },
  });

  if (user.stripe_customer_id) {
    return user.stripe_customer_id;
  }
  return createCustomer(customer, activeSite);
}

/**
 * Creates customer on stripe
 */
export async function createCustomer(customer, activeSite) {
  const stripe = getStripeInstance(activeSite);

  let customerName = '';
  if (customer.first_name) customerName += customer.first_name;
  if (customer.last_name) customerName += ` ${customer.last_name}`;

  const stripeCustomer = await stripe.customers.create({
    name: customerName,
    email: customer.email,
    metadata: {
      id: customer.id,
      site: activeSite.site_url,
    },
  });

  await datasource.updateUserDetails({
    data: {
      stripe_customer_id: stripeCustomer.id,
    },
    id: customer.id,
  });
  return stripeCustomer.id;
}

export async function createCheckoutSession({
  stripeCustomerId,
  period,
  callbackUrl,
  cancelUrl,
  activeSite,
}) {
  try {
    const stripe = getStripeInstance(activeSite);
    const checkoutSession = await stripe.checkout.sessions.create({
      customer: stripeCustomerId, // Required,
      mode: 'subscription',
      line_items: [
        {
          price: getPriceForPeriod(period, activeSite),
          quantity: 1,
        },
      ],
      success_url: callbackUrl,
      cancel_url: cancelUrl,
      allow_promotion_codes: true,
      consent_collection: {
        terms_of_service: 'required',
      },
      custom_text: {
        terms_of_service_acceptance: {
          message: `I agree to the [Terms of Service](${activeSite.site_url}/terms)`,
        },
      },
    });
    return checkoutSession.url;
  } catch (e) {
    console.error(e);
    throw new Error(e?.message || 'Something went wrong');
  }
}

export async function getCheckoutSession(sessionId, activeSite) {
  const stripe = getStripeInstance(activeSite);
  return stripe.checkout.sessions.retrieve(sessionId);
}

export async function getSubscription(subscriptionId, activeSite) {
  const stripe = getStripeInstance(activeSite);
  return stripe.subscriptions.retrieve(subscriptionId);
}

export async function getLatestSubscription(customer_id, activeSite) {
  const stripe = getStripeInstance(activeSite);
  const subscriptions = await stripe.subscriptions.list({
    customer: customer_id,
    status: 'all', // Fetch all statuses: active, past_due, canceled, etc.
    limit: 1, // Get the latest subscription
  });

  return subscriptions.data[0];
}

/**
 * Generates a link to stripe customer portal where user
 * can manage their subscriptions and update payment details
 */
export async function customerPortal({
  stripeCustomerId,
  activeSite,
  baseUrl,
}) {
  const stripe = getStripeInstance(activeSite);
  const session = await stripe.billingPortal.sessions.create({
    customer: stripeCustomerId,
    return_url: `${baseUrl}/profile`,
  });
  return session.url;
}

export async function createPaymentIntent({
  priceInCents,
  productId,
  customerId,
  customerStripeId,
  activeSite,
}) {
  const stripe = getStripeInstance(activeSite);
  return stripe.paymentIntents.create({
    amount: priceInCents,
    currency: 'usd',
    customer: customerStripeId,
    payment_method_types: ['card'],
    metadata: {
      productId,
      customerId,
    },
  });
}

export async function getPaymentIntent({ paymentIntentId, activeSite }) {
  const stripe = getStripeInstance(activeSite);
  return stripe.paymentIntents.retrieve(paymentIntentId);
}

export async function getEvent(eventId, activeSite) {
  const stripe = getStripeInstance(activeSite);
  return stripe.events.retrieve(eventId);
}

export const getSubscriptionURL = (period = 'monthly') => {
  return new Promise(async (resolve, reject) => {
    try {
      initiatePayment(15, {
        item_id: 'kg_pro',
        item_name: 'kg_pro_monthly',
        item_price: 15,
      });
      const { data } = await ApiService.instance.get(
        `/api/stripe/initiateSubscription?period=${period}`,
      );
      resolve(data.url);
    } catch (e) {
      let message = e.message;
      if (e.response) {
        message = e.response.data.message;
      }
      reject(message);
    }
  });
};
